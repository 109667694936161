import React from 'react';
import ReactDOM from 'react-dom';
import Flowers from './flower_database.json';
import ReactGA from 'react-ga';

//----------------------------------------------------------------------------
// Ernie added this for Google Analytics

ReactGA.initialize('G-6WNDZMN008');

// Track a pageview
ReactGA.pageview(window.location.pathname + window.location.search);

// Track a button click
ReactGA.event({
  category: 'Button',
  action: 'Click',
  label: 'Example Button'
});

// END OF: Ernie added this for Google Analytics
//----------------------------------------------------------------------------

 
/* class MainThumbs extends React.Component {
  handleMouseOver = (event) => {
    const img = event.target;
    if (window.innerWidth > 800) {
      img.src = 'thumbs_full/' + img.getAttribute('data-flower-thumb');
    }
  };

  handleMouseOut = (event) => {
    const img = event.target;
    // Remove the condition here to apply onMouseOut at all screen widths
    img.src = 'thumbs/' + img.getAttribute('data-flower-thumb');
  };

  render() {
    const thumbList = [];
    const flowers = Object.values(this.props.flowers);

    flowers.forEach((flower) => {
      thumbList.push(
        <button className="button_thumb" key={flower.object}>
          <a href={"?" + flower.object} onClick={this.props.clickHandler}>
            <div className="img_grid_div">
              <img
                src={'thumbs/' + flower.thumb}
                alt='thumbpics'
                data-flower-thumb={flower.thumb}
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
              />
              <figcaption>
                {flower.common}<br />
                <i>{flower.object.replace('_', ' ')} </i>
              </figcaption>
            </div>
          </a>
        </button>
      );
    });

    return <div className="thumb_div" id="thumb_div_id"> {thumbList} </div>;
  } */
// }

class MainThumbs extends React.Component {
  handleMouseOver = (event) => {
    const img = event.target;
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
    const screenWidth = window.innerWidth;

    if (!isMobile && screenWidth > 800) {
      img.src = 'thumbs_full/' + img.getAttribute('data-flower-thumb');
    }
  };

  handleMouseOut = (event) => {
    const img = event.target;
    img.src = 'thumbs/' + img.getAttribute('data-flower-thumb');
  };

  render() {
    const thumbList = [];
    const flowers = Object.values(this.props.flowers);

    flowers.forEach((flower) => {
      thumbList.push(
        <button className="button_thumb" key={flower.object}>
          <a href={"?" + flower.object} onClick={this.props.clickHandler}>
            <div className="img_grid_div">
              <img
                src={'thumbs/' + flower.thumb}
                alt='thumbpics'
                data-flower-thumb={flower.thumb}
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
              />
              <figcaption>
                {flower.common}<br />
                <i>{flower.object.replace('_', ' ')} </i>
              </figcaption>
            </div>
          </a>
        </button>
      );
    });

    return <div className="thumb_div" id="thumb_div_id"> {thumbList} </div>;
  }
}


class FlowerDetails extends React.Component {
  render() {
    const flower = this.props.flowers[this.props.flower];
    const imageList = [];
    flower.images.forEach(image => {
      imageList.push(<img src={'img/' + image} key={image} alt='flowerpic' />);
    });
	
    const mapList = [];
    flower.map.forEach(image => {
      mapList.push(<img src={'map/' + image} key={image} alt='map' onError={() => console.error('Failed to load image:', 'map/' + image)} />);
    });

    return (
      <div>
        <div id="search">
          <a href="/"><button>Back to Search</button></a>
        </div>
        <article id='flowerDescription'>
          <h2><i>{flower.genus} {flower.species}</i> ({flower.family_s})</h2>
          <h2>{flower.common}  ({flower.family_c})</h2>
          {imageList}
          <h4> <div dangerouslySetInnerHTML={{ __html: flower.description }} /></h4>
          <br /><br />
		  {mapList}
          <table>
            <tbody>
              <tr><td>Zones:</td><td>{flower.zone.join(', ')}</td></tr>
              <tr><td>Months of bloom:</td><td>{flower.month.join(', ')}</td></tr>
              <tr><td>Origin:</td><td>{flower.origin}</td></tr>
            </tbody>
          </table>
        </article>
      </div>
    );
  }
}


class DisplayMode {
  static COMMON = "Common";
  static SCIENTIFIC = "Scientific";
  static FAMILY = "Family";
}

class FlowerElement extends React.Component {
  render () {
    const flower = this.props.flower

    if (this.props.displayMode === DisplayMode.COMMON) {
      return (
        <a href={"?" + flower.object} onClick={this.props.clickHandler}>
        <li key={flower.object}>{flower.common}</li>
        </a>
      )
    } else {
        return (
        <a href={"?" + flower.object} onClick={this.props.clickHandler}>
        <li key={flower.object}>{flower.genus} {flower.species}</li>
        </a>        )
    }
  }
}

class FlowerList extends React.Component {

  render () {
    const rows = []

    const flowers = Object.values(this.props.flowers)

    flowers.forEach((flower, index) => {
      if (this.props.displayMode === DisplayMode.FAMILY &&
        (index === 0 || flowers[index - 1]['family_s'] !== flower['family_s'])) {
          rows.push(<span className='family' key={flower.family_s}>{flower.family_s} ({flower.family_c})</span>)
        }
      rows.push(
        <FlowerElement
          flower={flower} clickHandler={this.props.clickHandler} displayMode={this.props.displayMode} key={flower.object}
         />
        )
    })

    return (
      
        <ul id='flowerlist'>
          {rows}
        </ul>
      
    )
  }
}

class MainContent extends React.Component {
  constructor (props) {
    super(props)
    this.handleHomeClick = this.handleHomeClick.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleGroupingChange = this.handleGroupingChange.bind(this)

    const location = window.location.href
    const query = location.indexOf('?')
    var species = null
    if (query > 0) {
      species = location.substring(query + 1)
    }

    const grouping = window.localStorage.getItem('grouping') || DisplayMode.SCIENTIFIC

    const flowers = Object.values(this.props.flowers);
    const colorList = new Set();
    flowers.forEach((flower) => {
      flower.color.forEach((color) => {
        colorList.add(color);
      });
    });
    this.colorList = Array.from(colorList).sort()
    this.colorList[0] = "All";
    this.zoneList = ["All","Colorado Plateau","Plains", "Foothills", "Montane", "Subalpine", "Alpine"];
    this.monthList = ["All", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov"];
    this.chosenColor = window.localStorage.getItem('chosenColor') || "All"
    this.chosenZone = window.localStorage.getItem('chosenZone') || "All"
    this.chosenMonth = window.localStorage.getItem('chosenMonth') || "All"
    this.chosenName = window.localStorage.getItem('chosenName') || '';

    this.state = {
      selectedFlower: species,
      filteredFlowerList: this.getFilteredFlowers(),
      displayMode: grouping
    }
  }

  handleHomeClick () {
    this.chosenColor = "All"
    this.chosenZone = "All"
    this.chosenMonth = "All"
    this.chosenName = ''
    window.localStorage.setItem("chosenColor", this.chosenColor)
    window.localStorage.setItem("chosenZone", this.chosenZone)
    window.localStorage.setItem("chosenMonth", this.chosenMonth)
    window.localStorage.setItem("chosenName", this.chosenName)
    window.localStorage.setItem("flowerListPosition", 0)
    this.setState({selectedFlower: null,
    filteredFlowerList: this.props.flowers})
  }

  handleColorChange(event) {
    this.chosenColor = event.target.value
    window.localStorage.setItem("chosenColor", this.chosenColor)
    this.filterFlowers();
  }

  handleZoneChange(event) {
    this.chosenZone = event.target.value
    window.localStorage.setItem("chosenZone", this.chosenZone)
    this.filterFlowers();
  }

  handleMonthChange(event) {
    this.chosenMonth = event.target.value
    window.localStorage.setItem("chosenMonth", this.chosenMonth)
    this.filterFlowers();
  }

  handleNameChange(event) {
    this.chosenName = event.target.value
    window.localStorage.setItem("chosenName", this.chosenName)
    this.filterFlowers();
  }

  handleGroupingChange(event) {
    this.setState({displayMode: event.target.value})
    window.localStorage.setItem('grouping', event.target.value);
  }

  compareField(a, b, field) {
    return ((a[field] === b[field]) ? 0 : ((a[field] > b[field]) ? 1 : -1))
  }

  compareNames (a, b) {
    if (this.state.displayMode === DisplayMode.COMMON) {
      return this.compareField(a, b, 'common')
    } else if (this.state.displayMode === DisplayMode.SCIENTIFIC) {
      return this.compareField(a, b, 'object')
    }
    else {
      var val = this.compareField(a, b, 'family_s')
      if (val === 0) {
        return this.compareField(a, b, 'object')
      } else {
        return val
      }
    }
  }

  getFilteredFlowers() {
    var flowerList = {}
    Object.values(this.props.flowers).forEach((value) => {
      if (this.chosenColor !== "All") {
        var found = false;
        value.color.forEach((color) => {
          if (color === this.chosenColor) {
            found = true;
          }
        });
        if (!found) {
          return;
        }
      }
      if (this.chosenZone !== "All") {
        found = false;
        value.zone.forEach((zone) => {
          if (zone === this.chosenZone) {
            found = true;
          }
        });
        if (!found) {
          return;
        }
      }
      if (this.chosenMonth !== "All") {
        found = false;
        value.month.forEach((month) => {
          if (month === this.chosenMonth) {
            found = true;
          }
        });
        if (!found) {
          return;
        }
      }
      if (this.chosenName.length > 0) {
        var lowerName = this.chosenName.toLowerCase();
        if (value.genus.toLowerCase().includes(lowerName) ||
        value.genus2.toLowerCase().includes(lowerName) ||
        value.species.toLowerCase().includes(lowerName) ||
        value.species2.toLowerCase().includes(lowerName) ||
        value.family_c.toLowerCase().includes(lowerName) ||
        value.family_s.toLowerCase().includes(lowerName) ||
        value.common.toLowerCase().includes(lowerName)) {
          found = true
        } else {
          return;
        }
      }
      flowerList[value.object] = value;
    });
    return flowerList
  }

  filterFlowers() {
    this.setState({filteredFlowerList: this.getFilteredFlowers()});
  }

  saveScrollPosition() {
    window.localStorage.setItem("flowerListPosition", document.getElementById('left_column').scrollTop)
  }

  componentDidMount() {
    document.getElementById('left_column').scrollTop = window.localStorage.getItem("flowerListPosition") || 0
  }

  render () {
    const flowers = Object.values(this.state.filteredFlowerList)
    flowers.sort((a, b) => this.compareNames(a, b))

    let rightSide
    if (this.state.selectedFlower == null) {
      const colorOptions = []
      this.colorList.forEach((color) => {
        colorOptions.push(<option value={color} key={color}>{color}</option>)
      });
      let colorChooser = <select onChange={this.handleColorChange} value={this.chosenColor}>{colorOptions}</select>

      const zoneOptions = []
      this.zoneList.forEach((zone) => {
        zoneOptions.push(<option value={zone} key={zone}>{zone}</option>)
      });
      let zoneChooser = <select onChange={this.handleZoneChange} value={this.chosenZone}>{zoneOptions}</select>

      const monthOptions = []
      this.monthList.forEach((month) => {
        monthOptions.push(<option value={month} key={month}>{month}</option>)
      });
      let monthChooser = <select onChange={this.handleMonthChange} value={this.chosenMonth}>{monthOptions}</select>

      let nameField = <input value={this.chosenName} placeholder='flower name' onChange={this.handleNameChange}/>

      let count = Object.keys(this.state.filteredFlowerList).length
      const search =           <div id='search'>

      <div className='formField'>
        Color<br/>{colorChooser}
      </div>

      <div className='formField'>
        Zone<br/>{zoneChooser}
      </div>

      <div className='formField'>
        Month<br/>{monthChooser}
      </div>

      <div className='formField'>
        Name<br/>{nameField}
      </div>

      <div className='formButton'>
        <button onClick={this.handleHomeClick}>Reset</button>
      </div>

      <div className='search_count'>
        <h2>({count} Species Shown)</h2>
      </div>

    </div>
      rightSide = <div>
        {search}
        <MainThumbs flowers={flowers} clickHandler={this.saveScrollPosition}/>
      </div>
    } else {
      rightSide = <FlowerDetails flowers={this.props.flowers} flower={this.state.selectedFlower} />
    }

    return (
      <main id='content'>
        <section id='left_column'>
          <section>

            <div id="list_buttons">
              <select className="button_listType" value={this.state.displayMode} onChange={this.handleGroupingChange}>
                  <option value={DisplayMode.COMMON}>Common Names</option>
                  <option value={DisplayMode.SCIENTIFIC}>Scientific Names</option>
                  <option value={DisplayMode.FAMILY}>Families</option>
              </select>
            </div>

            <FlowerList flowers={flowers} displayMode = {this.state.displayMode} clickHandler={this.saveScrollPosition}/>
          </section>
        </section>
        <section id='right_column'>
       
        {rightSide}
       
        </section>
      </main>

    )
  }
}

ReactDOM.render(
  <MainContent flowers={Flowers} />,
  document.getElementById('root')
)
